* {
  scrollbar-width: 7px;
  scrollbar-color: #e9e9e9 #abadad;
}
*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #abadad;
  border-radius: 10px;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Avenir Book", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f2f2;
}

code {
  font-family: "Avenir Book", sans-serif;
}

#root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.body-signin {
  background-color: #000;
  color: #fff;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Avenir Black", sans-serif;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
}
/*custom fonts */
@font-face {
  font-family: "AvenirNext";
  src: url("./statics/fonts/AvenirNextCyr-Regular.eot") format("eot"); /*1E9*/
  src: local("AvenirNext"),
    url("./statics/fonts/AvenirNextCyr-Regular.eot?#iefix") format("eot"),
    /*1E6-8*/ url("./statics/fonts/AvenirNextCyr-Regular.ttf") format("ttf"),
    url("./statics/fonts/AvenirNextCyr-Regular.woff") format("woff"),
    url("./statics/fonts/AvenirNextCyr-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "AvenirNext-Medium";
  src: url("./statics/fonts/AvenirNextCyr-Medium.eot") format("eot"); /*1E9*/
  src: local("AvenirNext-Medium"),
    url("./statics/fonts/AvenirNextCyr-Medium.eot?#iefix") format("eot"),
    /*1E6-8*/ url("./statics/fonts/AvenirNextCyr-Medium.ttf") format("ttf"),
    url("./statics/fonts/AvenirNextCyr-Medium.woff") format("woff"),
    url("./statics/fonts/AvenirNextCyr-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "AvenirNext-DemiBold";
  src: url("./statics/fonts/AvenirNextCyr-Demi.eot") format("eot"); /*1E9*/
  src: local("AvenirNext-DemiBold"),
    url("./statics/fonts/AvenirNextCyr-Demi.eot?#iefix") format("eot"),
    /*1E6-8*/ url("./statics/fonts/AvenirNextCyr-Demi.ttf") format("ttf"),
    url("./statics/fonts/AvenirNextCyr-Demi.woff") format("woff"),
    url("./statics/fonts/AvenirNextCyr-Demi.woff2") format("woff2");
}
@font-face {
  font-family: "Avenir Black";
  src: url("./statics/fonts/Avenir Black.ttf") format("ttf");
  src: local("Avenir Black");
}
@font-face {
  font-family: "Avenir Book";
  src: url("./statics/fonts/Avenir Book.ttf") format("ttf");
  src: local("Avenir Book");
}
@font-face {
  font-family: "Futura Bold";
  src: url("./statics/fonts/Futura Bold.ttf") format("ttf");
  src: local("Futura Bold");
}
@font-face {
  font-family: "Futura Light";
  src: url("./statics/fonts/Futura Light.ttf") format("ttf");
  src: local("Futura Light");
}

@media screen and (max-width: 768px) {
  #container {
    width: 96%;
  }
  #root-analytics,
  #navbar {
    padding: 0 10px;
  }
  #date-picker {
    flex-wrap: nowrap;
    margin-top: 20px;
  }
  body,
  html {
    overflow-x: hidden;
    width: 100%;
  }
  #metrics {
    grid-template-columns: unset !important;
    display: unset !important;
    grid-gap: 0;
  }
  #city-country {
    grid-template-columns: unset !important;
    grid-gap: 0;
    width: auto !important;
    padding: 0px;
  }

  #city-country > * {
    width: 98%;
  }

  div[id*="mediaContainer"] {
    width: 100% !important;
    margin-right: 0px;
  }
  #top5 {
    padding: 8px 17px 0px 2px;
    grid-template-columns: unset !important;
  }
  #decisions {
    padding: 10px;
  }
  div[id*="social-post"] {
    padding: 10px;
  }
  #selector {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 1150px) {
  #age-gender {
    display: unset !important;
    grid-template-columns: unset !important;
    grid-gap: 0;
  }
  #media {
    flex-flow: row wrap;
  }
  div[id*="mediaContainer"] {
    width: calc(100% / 2 - 20px);
    margin-bottom: 34.5px;
  }
  div[id*="mediaContainer"]:nth-child(2) {
    margin-right: 0px;
  }
}
@media screen and (max-width: 800px) {
  #stats {
    margin-left: 0px;
  }
}
a[title="Interactive JavaScript maps"] {
  display: none !important;
}
@media screen and (min-width: 1600px) {
  #metrics {
    grid-template-columns: 1fr 1fr;
  }
  #city-country {
    grid-template-columns: 1fr 1fr !important;
  }
}

@media screen and (min-width: 1700px) {
  #content-post > :nth-child(2) {
    width: 460px;
  }
}

@media screen and (max-width: 600px) {
  #decisions {
    margin-top: 20px !important;
    padding: 10px;
    margin-left: 0;
  }

  div[id*="social-post"] {
    margin-right: 0 !important;
    padding: 10px;
  }
}
@media screen and (max-width: 1000px) {
  #be-planned-title {
    height: auto;
  }
  #analytics,
  #be-planned,
  #grid {
    padding-top: 60px;
  }
  #no-posts {
    margin-top: 0px !important;
  }
}
