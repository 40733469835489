[class*="materialui-daterange-picker-makeStyles-filled"] {
  background-color: #000 !important;
}
[class*="materialui-daterange-picker-makeStyles-outlined"] {
  border: 1px solid #000 !important;
}
[class*="materialui-daterange-picker-MuiList-root-"] {
  width: 7rem !important;
}
[class*="materialui-daterange-picker-makeStyles-highlighted"] {
  background-color: #abadad !important;
}
.MuiIconButton-root:hover {
  background-color: #abadad !important;
}

@media screen and (max-width: 1130px) {
  .materialui-daterange-picker-MuiPaper-root-1143 {
    width: 280px;
    overflow: auto;
  }

  .materialui-daterange-picker-makeStyles-dateRangePicker-2 {
    top: -84px;
    height: 500px;
    right: -37%;
  }

  .materialui-daterange-picker-makeStyles-dateRangePicker-2 > div > div {
    width: 290px;
  }

  .materialui-daterange-picker-makeStyles-dateRangePicker-2
    > div
    > div
    > div:last-child {
    background: white;
    margin-left: -1px;
  }

  .materialui-daterange-picker-makeStyles-dateRangePicker-2
    > div
    > div
    > div
    > div:first-child {
    padding: 20px;
  }

  .materialui-daterange-picker-makeStyles-dateRangePicker-2
    > div
    > div
    > div
    > div:last-child {
    display: flex;
    flex-direction: column;
  }

  .grid-date-picker {
    top: -240px;
  }
}

@media screen and (max-width: 768px) {
  .materialui-daterange-picker-makeStyles-dateRangePicker-2 {
    top: -127px;
  }

  .materialui-daterange-picker-makeStyles-dateRangePicker-2 > div > div {
    width: 330px;
  }

  .materialui-daterange-picker-makeStyles-dateRangePicker-2 > div > div > div {
    background-color: #fff;
  }
}
